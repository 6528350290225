@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

html {
  font-size: 10px;
  font-family: 'Mulish', sans-serif;
  padding: 0;
  margin: 0;
  color: #FFFFFF;
}

body {
  font-size: 1.4rem;
  font-family: 'Mulish', sans-serif;
  padding: 0;
  margin: 0;
  background: rgb(68,79,90);
  background: linear-gradient(135deg, rgba(68,79,90,1) 0%, rgba(62,65,73,1) 100%);
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

/*.app {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  width: 320px;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*}*/

.bg-gradient-dark-blue {
  background: rgb(68,79,90);
  background: linear-gradient(135deg, rgba(68,79,90,1) 0%, rgba(62,65,73,1) 100%);
}
.bg-dark-blue {
  background-color: #444F5A;
}

.bg-dark-gray {
  background-color: #515B65;
}
.bg-dark-black {
  background-color: #3E4149;
}

.bg-blue {
  background-color: #99CEFF;
}
.bg-red {
  background-color: #FF9999;
}

.bg-gray {
  background-color: #888888;
}

@keyframes animationButtonRedToBlue {
  0% {
    background: linear-gradient(110deg, #99CEFF 0%, #ff9999 0%);
  }
  5% {
    background: linear-gradient(110deg, #99CEFF 10%, #ff9999 10%);
  }
  10% {
    background: linear-gradient(110deg, #99CEFF 20%, #ff9999 20%);
  }
  15% {
    background: linear-gradient(110deg, #99CEFF 30%, #ff9999 30%);
  }
  20% {
    background: linear-gradient(110deg, #99CEFF 40%, #ff9999 40%);
  }
  25% {
    background: linear-gradient(110deg, #99CEFF 50%, #ff9999 50%);
  }
  30% {
    background: linear-gradient(110deg, #99CEFF 60%, #ff9999 60%);
  }
  35% {
    background: linear-gradient(110deg, #99CEFF 70%, #ff9999 70%);
  }
  40% {
    background: linear-gradient(110deg, #99CEFF 80%, #ff9999 80%);
  }
  45% {
    background: linear-gradient(110deg, #99CEFF 90%, #ff9999 90%);
  }

  50% {
    background: linear-gradient(110deg, #ff9999 0%, #99CEFF 0%);
  }
  55% {
    background: linear-gradient(110deg, #ff9999 10%, #99CEFF 10%);
  }
  60% {
    background: linear-gradient(110deg, #ff9999 20%, #99CEFF 20%);
  }
  65% {
    background: linear-gradient(110deg, #ff9999 30%, #99CEFF 30%);
  }
  70% {
    background: linear-gradient(110deg, #ff9999 40%, #99CEFF 40%);
  }
  75% {
    background: linear-gradient(110deg, #ff9999 50%, #99CEFF 50%);
  }
  80% {
    background: linear-gradient(110deg, #ff9999 60%, #99CEFF 60%);
  }
  85% {
    background: linear-gradient(110deg, #ff9999 70%, #99CEFF 70%);
  }
  90% {
    background: linear-gradient(110deg, #ff9999 80%, #99CEFF 80%);
  }
  95% {
    background: linear-gradient(110deg, #ff9999 90%, #99CEFF 90%);
  }
  100% {
    background: linear-gradient(110deg, #ff9999 100%, #99CEFF 100%);
  }
}

@keyframes animationButtonBlueToRed {
  0% {
    background: linear-gradient(110deg, #ff9999 0%, #99CEFF 0%);
  }
  5% {
    background: linear-gradient(110deg, #ff9999 10%, #99CEFF 10%);
  }
  10% {
    background: linear-gradient(110deg, #ff9999 20%, #99CEFF 20%);
  }
  15% {
    background: linear-gradient(110deg, #ff9999 30%, #99CEFF 30%);
  }
  20% {
    background: linear-gradient(110deg, #ff9999 40%, #99CEFF 40%);
  }
  25% {
    background: linear-gradient(110deg, #ff9999 50%, #99CEFF 50%);
  }
  30% {
    background: linear-gradient(110deg, #ff9999 60%, #99CEFF 60%);
  }
  35% {
    background: linear-gradient(110deg, #ff9999 70%, #99CEFF 70%);
  }
  40% {
    background: linear-gradient(110deg, #ff9999 80%, #99CEFF 80%);
  }
  45% {
    background: linear-gradient(110deg, #ff9999 90%, #99CEFF 90%);
  }

  50% {
    background: linear-gradient(110deg, #99CEFF 0%, #ff9999 0%);
  }
  55% {
    background: linear-gradient(110deg, #99CEFF 10%, #ff9999 10%);
  }
  60% {
    background: linear-gradient(110deg, #99CEFF 20%, #ff9999 20%);
  }
  65% {
    background: linear-gradient(110deg, #99CEFF 30%, #ff9999 30%);
  }
  70% {
    background: linear-gradient(110deg, #99CEFF 40%, #ff9999 40%);
  }
  75% {
    background: linear-gradient(110deg, #99CEFF 50%, #ff9999 50%);
  }
  80% {
    background: linear-gradient(110deg, #99CEFF 60%, #ff9999 60%);
  }
  85% {
    background: linear-gradient(110deg, #99CEFF 70%, #ff9999 70%);
  }
  90% {
    background: linear-gradient(110deg, #99CEFF 80%, #ff9999 80%);
  }
  95% {
    background: linear-gradient(110deg, #99CEFF 90%, #ff9999 90%);
  }
  100% {
    background: linear-gradient(110deg, #99CEFF 100%, #ff9999 100%);
  }
}

@keyframes animationTest {
  0% {
    background: radial-gradient(circle, rgba(255,153,153,1) 0%, rgba(153,206,255,1) 80%);
  }
  100% {
    background: radial-gradient(circle, rgba(255,153,153,1) 100%, rgba(153,206,255,1) 80%);
  }
}