.calendar-day {
    text-align: center;
    color: #FFFFFF;
    width: 44px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #444F5A;
    cursor: pointer;
}
.calendar-day:hover {
    background-color: #515B65;
}


.calendar-day-img-star {
    position: relative;
    right: -13px;
    top: -13px;
}


.calendar-day-text {
    text-align: center;
    position: absolute;
    font-size: 1.8rem;
    color: #FFFFFF;
}
.calendar-day-text-bold {
    text-align: center;
    font-weight: bold;
}
.calendar-day-text-lighter {
    text-align: center;
    font-weight: lighter;
}
.calendar-day-text-pale {
    text-align: center;
    font-weight: lighter;
    color: #888888;
}


.calendar-day-circle {
    text-align: center;
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 100%;
}
.calendar-day-ring-thin-red {
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: absolute;
    width: 36px;
    height: 36px;
    border: 1px solid #FF9999;
    border-radius: 100%;
}
.calendar-day-ring-thin-dotted-red {
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: absolute;
    width: 36px;
    height: 36px;
    border: 4px dotted #FF9999;
    border-radius: 100%;
}
.calendar-day-ring-medium-red {
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: absolute;
    width: 36px;
    height: 36px;
    background-color: rgba(255, 153, 153, 0.5);
    border: 5px solid #FF9999;
    border-radius: 100%;
}
.calendar-day-ring-medium-blue {
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: absolute;
    width: 36px;
    height: 36px;
    border: 4px solid #99CEFF;
    border-radius: 100%;
}
.calendar-day-ring-thin-blue {
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: absolute;
    width: 36px;
    height: 36px;
    border: 1px solid #99CEFF;
    border-radius: 100%;
}