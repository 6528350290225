.calendar {
    width: 308px;
    background-color: #444F5A;
    border-radius: 22px;
    height: 352px;
    overflow: hidden;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}


.calendar-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 44px;
}
.calendar-navigation-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: #FFFFFF;
    color: #FFFFFF;
}


.calendar-days-of-week {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 44px;
}


.calendar-week {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 44px;
}