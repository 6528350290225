.summary-big {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    width: 249px;
    margin: 0;
    padding: 5px;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.summary-big-title-first {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 1.6rem;
    color: #FFFFFF;
    font-weight: bold;
    text-transform: uppercase;
}
.summary-big-text-first {
    text-align: center;
    padding: 0;
    margin: 0 0 5px 0;
    font-size: 1.4rem;
    color: #888888;
    font-weight: lighter;
    text-transform: uppercase;
}
.summary-big-title-second {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
    color: #FF9999;
    font-weight: bold;
    text-transform: uppercase;
}
.summary-big-text-second {
    text-align: center;
    padding: 0;
    margin: 0 0 5px 0;
    font-size: 2rem;
    color: #FFFFFF;
    font-weight: lighter;
    text-transform: uppercase;
}
.summary-big-title-third {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
    color: #99CEFF;
    font-weight: bold;
    text-transform: uppercase;
}
.summary-big-text-third {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 2rem;
    color: #FFFFFF;
    font-weight: lighter;
    text-transform: uppercase;
}