.button {
    text-align: center;
    color: #FFFFFF;
    width: 44px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #444F5A;
    border-radius: 22px;
    margin: 0;
    cursor: pointer;

    position: relative;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.button:hover {
    background-color: #515B65;
}


.button-img {
    width: 44px;
    height: 44px;
}