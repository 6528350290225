.calendar-page {
    /*width: 100vw;*/
    width: 308px;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.calendar-page-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 5px 0 0 0;
}
.calendar-page-navigation-left {
    height: 204px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.calendar-page-navigation-right {
    height: 204px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
