.modal-full {
    /*padding: 20px 0;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/

    padding: 10px;
    margin: 10px;
    width: calc(100vw - 40px);
    height: calc(100% - 40px);
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #444F5A;
    border-radius: 22px 22px 34px 34px;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-full-form {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.modal-full-title {
    /*padding: 0;*/
    /*margin: 0 0 20px 0;*/
    /*font-size: 1.8rem;*/
    /*color: #FFFFFF;*/
    /*font-weight: bold;*/
    /*text-transform: uppercase;*/

    text-align: center;
    padding: 0;
    margin: 10px 0 20px 0;
    font-size: 1.8rem;
    color: #FFFFFF;
    font-weight: bold;
    text-transform: uppercase;
}


.modal-full-label {
    /*width: 270px;*/
    /*padding: 0;*/
    /*margin: 0;*/
    /*font-size: 1.2rem;*/
    /*color: #FFFFFF;*/
    /*font-weight: bold;*/

    width: calc(100% - 50px);
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    color: #FFFFFF;
    font-weight: bold;
}
.modal-full-input {
    /*width: 270px;*/
    /*height: 50px;*/
    /*border-radius: 100px;*/
    /*border: none;*/
    /*padding: 0 25px;*/
    /*margin: 0 0 10px 0;*/
    /*font-size: 1.4rem;*/
    /*color: #FFFFFF;*/
    /*background-color: #515B65;*/

    width: calc(100% - 50px);
    height: 50px;
    border-radius: 100px;
    border: none;
    padding: 0 25px;
    margin: 0 0 10px 0;
    font-size: 1.4rem;
    color: #FFFFFF;
    background-color: #515B65;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-full-input-readonly {
    /*width: 270px;*/
    /*height: 50px;*/
    /*border-radius: 100px;*/
    /*border: none;*/
    /*padding: 0 25px;*/
    /*margin: 0 0 10px 0;*/
    /*font-size: 1.4rem;*/
    /*color: #FFFFFF;*/
    /*background-color: rgba(255, 255, 255, 0);*/

    cursor: text;
    width: calc(100% - 50px);
    height: 50px;
    border-radius: 100px;
    border: none;
    padding: 0 25px;
    margin: 0 0 10px 0;
    font-size: 1.4rem;
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0);
}
.modal-full-button {
    /*font-size: 1.4rem;*/
    /*font-weight: bold;*/
    /*text-transform: uppercase;*/
    /*color: #FFFFFF;*/
    /*text-align: center;*/
    /*line-height: 50px;*/
    /*width: 270px;*/
    /*padding: 0 25px;*/
    /*margin: 10px 0 0 0;*/
    /*height: 50px;*/
    /*border-radius: 100px;*/
    /*background-color: #FF9999;*/
    /*border: none;*/

    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    width: 100%;
    padding: 0;
    margin: 10px 0 0 0;
    height: 50px;
    border-radius: 100px;
    background-color: #FF9999;
    border: none;
    cursor: pointer;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-full-button-animation {
    /*font-size: 1.4rem;*/
    /*font-weight: bold;*/
    /*text-transform: uppercase;*/
    /*color: #FFFFFF;*/
    /*text-align: center;*/
    /*line-height: 50px;*/
    /*width: 270px;*/
    /*padding: 0 25px;*/
    /*margin: 10px 0 0 0;*/
    /*height: 50px;*/
    /*border-radius: 100px;*/
    /*background-color: #FF9999;*/
    /*border: none;*/

    /*animation-name: animationButtonRedToBlue;*/
    /*animation-duration: 1s;*/
    /*animation-fill-mode: forwards;*/
    /*animation-iteration-count: infinite;*/
    /*animation-timing-function: steps(21, end);*/

    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    width: 100%;
    padding: 0;
    margin: 10px 0 0 0;
    height: 50px;
    border-radius: 100px;
    background-color: #FF9999;
    border: none;
    cursor: pointer;

    animation-name: animationButtonRedToBlue;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps(21, end);

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}


.modal-full-button-helper {
    /*font-size: 1.4rem;*/
    /*font-weight: bold;*/
    /*text-transform: uppercase;*/
    /*color: #FFFFFF;*/
    /*text-align: center;*/
    /*line-height: 25px;*/
    /*width: 270px;*/
    /*padding: 0 25px;*/
    /*margin: 20px 0 0 0;*/
    /*height: 50px;*/
    /*border-radius: 100px;*/

    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 25px;
    width: 100%;
    padding: 0;
    margin: 10px 0 0 0;
    height: 50px;
    border-radius: 100px;
}
.modal-full-error-color {
    color: #FF9999;
}

.modal-full-input:hover {
    background-color: #3E4149;
}
.modal-full-button:hover  {
    background-color: #99CEFF;
}