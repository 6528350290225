.modal-pop {
    padding: 10px;
    margin: 10px;
    width: calc(100vw - 40px);
    height: calc(100% - 40px);
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #444F5A;
    border-radius: 32px 32px 32px 32px;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}



/* 1 */
.modal-pop-nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.modal-pop-nav-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.modal-pop-title {
    text-align: center;
    padding: 0;
    margin: 10px 0 20px 0;
    font-size: 1.8rem;
    color: #FFFFFF;
    font-weight: bold;
    text-transform: uppercase;
}



/* 2 */
.modal-pop-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.modal-pop-label {
    width: calc(100% - 50px);
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    color: #FFFFFF;
    font-weight: bold;
}
.modal-pop-input {
    width: calc(100% - 50px);
    height: 50px;
    border-radius: 100px;
    border: none;
    padding: 0 25px;
    margin: 0 0 10px 0;
    font-size: 1.4rem;
    color: #FFFFFF;
    background-color: #515B65;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-pop-input-readonly {
    width: calc(100% - 50px);
    height: 50px;
    border-radius: 100px;
    border: none;
    padding: 0 25px;
    margin: 0 0 10px 0;
    font-size: 1.4rem;
    color: #FFFFFF;
    cursor: text;
    background-color: rgba(255, 255, 255, 0);
}
.modal-pop-button {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    width: 100%;
    padding: 0;
    margin: 10px 0 0 0;
    height: 50px;
    border-radius: 100px;
    background-color: #FF9999;
    border: none;
    cursor: pointer;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-pop-button-animation {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    width: 100%;
    padding: 0;
    margin: 10px 0 0 0;
    height: 50px;
    border-radius: 100px;
    background-color: #FF9999;
    border: none;
    cursor: pointer;

    animation-name: animationButtonRedToBlue;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps(21, end);

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}


.modal-pop-button-helper {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 25px;
    width: 100%;
    padding: 0;
    margin: 10px 0 0 0;
    height: 50px;
    border-radius: 100px;
}
.modal-pop-error-color {
    color: #FF9999;
}



/* Subscriptions */
.modal-pop-subscriptions-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: calc(100% - 20px);
    border-radius: 22px;
    border: none;
    margin: 0 0 10px 0;
    padding: 10px;
    font-size: 1.4rem;
    color: #FFFFFF;
    background-color: #515B65;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-pop-subscriptions-block:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: calc(100% - 20px);
    border-radius: 22px;
    border: none;
    margin: 0 0 0 0;
    padding: 10px;
    font-size: 1.4rem;
    color: #FFFFFF;
    background-color: #515B65;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-pop-subscriptions-block-id {
    width: 100%;
    padding: 0;
    margin: 0 0 10px;
    font-size: 1.4rem;
    color: #888888;
    font-weight: lighter;
}
.modal-pop-subscriptions-block-name {
    width: 100%;
    padding: 0;
    margin: 0 0 10px 0;
    font-size: 1.8rem;
    color: #FFFFFF;
    font-weight: bold;
}
.modal-pop-subscriptions-block-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.modal-pop-subscriptions-block-button-red {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    width: 40%;
    padding: 0;
    margin: 0;
    height: 50px;
    border-radius: 14px;
    background-color: #FF9999;
    border: none;
    cursor: pointer;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-pop-subscriptions-block-button-blue {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    width: 40%;
    padding: 0;
    margin: 0;
    height: 50px;
    border-radius: 14px;
    background-color: #99CEFF;
    border: none;
    cursor: pointer;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-pop-subscriptions-block-button-red-animation {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    width: 40%;
    padding: 0;
    margin: 0;
    height: 50px;
    border-radius: 14px;
    background-color: #FF9999;
    border: none;
    cursor: pointer;

    animation-name: animationButtonRedToBlue;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps(21, end);

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.modal-pop-subscriptions-block-button-blue-animation {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    width: 40%;
    padding: 0;
    margin: 0;
    height: 50px;
    border-radius: 14px;
    background-color: #99CEFF;
    border: none;
    cursor: pointer;

    animation-name: animationButtonBlueToRed;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps(21, end);

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.modal-pop-input:hover {
    background-color: #3E4149;
}
.modal-pop-button:hover {
    background-color: #99CEFF;
}
.modal-pop-subscriptions-block-button-red:hover {
    background-color: #ffbcbc;
}
.modal-pop-subscriptions-block-button-blue:hover {
    background-color: #b6dbff;
}